import React from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';

import {
  registrationStages,
  paymentPlans,
  actions as registrationActions
} from '../../store/reducers/registration';
import {
  AccordionBlocks,
  AccordionBlock,
} from '../../components/accordion-blocks';
import PersonalDetail from './PersonalDetail';
import CourseType from './CourseType';
import PaymentPlans from './PaymentPlans';
import PaymentMethod from './PaymentMethod';
import Header from '../header';
import Footer from '../footer';

import User, { StudentCourse } from '../../models/user';
import { ContainerLoader, FullPageLoader } from '../../components/loader';
import { State } from '../../store/interfaces';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import '../../styles/personalInformation.scss';

const Step: React.SFC = () => {
  const {
    user,
    currentStage,
    courseType,
    course,
    summary,
    paymentPlan,
  } = useSelector((state: State) => state.registration);

  const dispatch = useDispatch();
  if (currentStage === registrationStages.courseSelected && !course && user?.StudentCourse) {
    dispatch(
      registrationActions.setCourse(
        user.StudentCourse,
        user.StudentCourse.courseType
          ? user.StudentCourse.courseType.name.en
          : ''
      )
    );
  }
  
  const {
    currentIndex,
    personalDetailsSummary,
    courseTypeSummary,
    paymentPlanSummary,
  } = getIndexAndSummaries(
    user,
    currentStage,
    courseType || '',
    course,
    summary,
    paymentPlan || ''
  );

  const history = useHistory();
  React.useEffect(() => {
    document.body.scrollIntoView({ behavior: 'smooth' });
  }, []);

  if (currentIndex === 4) {
    // Registration
    history.replace('/signup/thank-you');
    return <FullPageLoader></FullPageLoader>;
  }

  return (
    <>
      <Header />
      <div className="bg-muted/5 personalaInformation">
        <div className="pt-[110px] md:pt-[200px] pb-[85px]">
          {!user && (
            <ContainerLoader
              height={500}
              text={'Fetching registration details..'}
            />
          )}
          {user && (
            <div className="container">
              <h1 className="text-2xl md:text-[32px] font-semibold md:font-bold">
                Hi, {user.name}
              </h1>
              <p className="text-sm md:text-base">
                Please fill the following Steps
              </p>

              <AccordionBlocks currentIndex={currentIndex}>
                <AccordionBlock
                  title="Personal Detail"
                  icon={'PersonalDetailIcon'}
                  actionText="Please fill your personal details"
                  summaries={personalDetailsSummary}
                >
                  <PersonalDetail />
                </AccordionBlock>
                <AccordionBlock
                  title="Course Type"
                  icon={'CourseTypeIcon'}
                  actionText="Choose your course type"
                  summaries={courseTypeSummary}
                >
                  <CourseType />
                </AccordionBlock>
                <AccordionBlock
                  title="Choose Payment Plan"
                  icon={'PaymentPlanIcon'}
                  actionText="Choose your payment plan that works best for you"
                  summaries={paymentPlanSummary}
                >
                  <PaymentPlans />
                </AccordionBlock>
                <AccordionBlock
                  title="Complete Payment"
                  icon={'PaymentMethodIcon'}
                  actionText="Let's finish powering you up"
                >
                  <PaymentMethod />
                </AccordionBlock>
              </AccordionBlocks>
            </div>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};

Step.defaultProps = {
  personalDetailsSummary: {},
  courseTypesSummary: {},
  paymentPlanSummary: {},
  courseType: '',
  course: {},
  summary: {},
  paymentPlan: '',
};

const getPersonalDetailsSummary = (user?: User) =>
  user
    ? {
        licensetype: {
          label: 'Your required license type',
          value: user.studentDetails
            ? user.studentDetails.requiredLicenseCategory
            : '',
        },
        nationality: {
          label: 'and Nationality is',
          value: user.nationality,
        },
      }
    : {};

const getCourseTypeSummary = (courseType: string, course: StudentCourse) =>
  courseType && course && course.amount
    ? {
        coursetype: {
          label: 'Your choose course as',
          value: courseType,
        },
        courseprice: {
          label: 'course price is',
          value:
            course && course.amount
              ? `AED ${course.amount.totalAmount - course.amount.totalDiscount}`
              : '',
        },
      }
    : {};
const getPaymentPlanSummary = (
  fullPaymentSelected: boolean,
  totalAmount: number
) => ({
  paymentplan: {
    label: 'Your choose payment plan as',
    value: fullPaymentSelected ? 'Full Payment' : 'Stage payment',
  },
  paymableamount: {
    label: 'and amount payable is',
    value: `AED ${totalAmount.toFixed(2)}`,
  },
});

const getIndexAndSummaries = (
  user: User | undefined,
  currentStage: string,
  courseType?: string,
  course?: any,
  summary?: any,
  paymentPlan?: string
) => {
  const totalAmount =
    summary.totalAmount + summary.totalTax - summary.totalDiscount;
  const fullPaymentSelected = paymentPlan === paymentPlans.fullPayment;
  switch (currentStage) {
    case registrationStages.profileDetails:
      return {
        currentIndex: 0,
        personalDetailsSummary: {},
        courseTypeSummary: {},
        paymentPlanSummary: {},
      };
    case registrationStages.courseSelection:
      return {
        currentIndex: 1,
        personalDetailsSummary: getPersonalDetailsSummary(user),
        courseTypeSummary: {},
        paymentPlanSummary: {},
      };
    case registrationStages.courseSelected:
      return {
        currentIndex: 2,
        personalDetailsSummary: getPersonalDetailsSummary(user),
        courseTypeSummary: getCourseTypeSummary(courseType as string, course),
        paymentPlanSummary: {},
      };
    case registrationStages.completePayment:
      return {
        currentIndex: 3,
        personalDetailsSummary: getPersonalDetailsSummary(user),
        courseTypeSummary: getCourseTypeSummary(courseType as string, course),
        paymentPlanSummary: getPaymentPlanSummary(
          fullPaymentSelected,
          totalAmount
        ),
      };
    case registrationStages.finished:
      return { currentIndex: 4 };
    default:
      return { currentIndex: 0 };
  }
};

export default Step;
