import * as http from './http';
import ApiService from './service';

const serviceEndpoint = 'user/v1';

export default class PaymentService extends ApiService {
  public async verifyPayment(
    referenceCode: string,
    retryPayment: boolean,
    referralAmount?: number
  ) {
    const url = `${this.apiDomain}/${serviceEndpoint}/student/verify-payment`;
    const referralAmountData = referralAmount || 0;
    return http.post(
      url,
      {
        paymentReference: referenceCode,
        retryPayment,
        referralAmount: referralAmountData,
      },
      this.token
    );
  }
  public async getAdditionalFees() {
    const url = `${this.apiDomain}/${serviceEndpoint}/additional-fees`;
    return http.get(url, this.token);
  }
  public async getAdditionalFeeDetails(type: string, userId: string) {
    const url = `${this.apiDomain}/${serviceEndpoint}/additional-fees/${userId}/${type}`;
    return http.get(url, this.token);
  }
  public async initiatePayment(data: any) {
    const url = `${this.apiDomain}/${serviceEndpoint}/additional-fees`;
    return http.post(url, data, this.token);
  }
  public async collectAdditionalFees(data: any, userId: string) {
    const url = `${this.apiDomain}/${serviceEndpoint}/additional-fees/${userId}`;
    return http.post(url, data, this.token);
  }

  public async verifyAdditionalFees(data: any, userId: string) {
    const url = `${this.apiDomain}/${serviceEndpoint}/additional-fees/${userId}/verify-payment`;
    return http.post(url, data, this.token);
  }
}
