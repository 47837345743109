import React, { lazy, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { RouteKeys } from './route-keys';
import AuthenticateRoute from './auth/AuthenticateRoute';
import ErrorBoundary from './ErrorBoundary';

// import Auth from './auth';
// import Home from './home';
// import NotFound from './notfound';
// import DashboardPage from './dashboard';
import RegisterStep1 from './register-step1';
import RegisterStep2 from './register-step2';
import RegisterStep3 from './register-step3';
import RegisterThankYou from './register-thank-you';
import { PaymentCallBackPage } from './payment';
// import LectureVideo from './lecture-video';
// import QuestionAnswer from './question-answer';
// import PdfInvoicePage from './pdf-invoice';
// import ScheduleDetailsPage from './schedule-details';
import VerifyUserEmail from './verify-email';
import RedirectIfAuthenticatedRoute from './auth/RedirectIfAuthenticatedRoute';
import { ContainerLoader } from '../components/loader';
// import PdfContractPage from './pdf-contract';
// import Dev from './dev';

// lazy loaded components (components should have a default export to lazy load)
const Auth = lazy(() => import('./auth'));
const Home = lazy(() => import('./home'));
const NotFound = lazy(() => import('./notfound'));
const DashboardPage = lazy(() => import('./dashboard'));

const LectureVideo = lazy(() => import('./lecture-video'));
const QuestionAnswer = lazy(() => import('./question-answer'));
const PdfInvoicePage = lazy(() => import('./pdf-invoice'));
const LumpsumPdfInvoicePage = lazy(() => import('./lumpsum-pdf-invoice'));
const ScheduleDetailsPage = lazy(() => import('./schedule-details'));
const PdfContractPage = lazy(() => import('./pdf-contract'));
const PdfRefundDocument = lazy(() => import('./pdf-refund-document'));
const PdfRefundDocumentCorporate = lazy(() =>
  import('./pdf-refund-document-corporate')
);
const PdfClearanceCertificate = lazy(() =>
  import('./pdf-clearance-certificate')
);
const BookAppointment = lazy(() => import('./book-an-appointment'));
const BookAppointmentThankYou = lazy(() =>
  import('./book-an-appointment-thank-you')
);
const CustomerFeedback = lazy(() => import('./customer-feedback'));
const CustomerFeedbackThankYou = lazy(() =>
  import('./customer-feedback-thank-you')
);
const CorporateLumpsumPdfInvoicePage = lazy(() =>
  import('./corporate-lumpsum-pdf-invoice')
);

const CorporatePdfInvoice = lazy(() => import('./pdf-invoice-corporate'));
const PreregistrationPdfInvoicePage = lazy(() => import('./preregistration-pdf-invoice'));
// const MyReferralTab = lazy(() => import('./'));
class Routes extends React.Component {
  render() {
    return (
      <ErrorBoundary>
        <Suspense
          fallback={<ContainerLoader height={500} text={'Loading...'} />}
        >
          <Switch>
            {/* <Route exact path="/" component={Auth} /> */}
            <Route path={RouteKeys.Auth} component={Auth} />
            {/* <Route path={RouteKeys.Dev} component={Dev} /> */}
            <Route path={RouteKeys.PdfInvoice} component={PdfInvoicePage} />
            <Route
              path={RouteKeys.LumpsumPdfInvoice}
              component={LumpsumPdfInvoicePage}
            />
            <Route
              path={RouteKeys.CorporatePdfInvoice}
              component={CorporatePdfInvoice}
            />
            <Route
              path={RouteKeys.CorporateLumpsumPdfInvoice}
              component={CorporateLumpsumPdfInvoicePage}
            />
            <Route path={RouteKeys.PdfContract} component={PdfContractPage} />
            <Route
              path={RouteKeys.PaymentCallback}
              component={PaymentCallBackPage}
            />
            <RedirectIfAuthenticatedRoute
              exact
              path={RouteKeys.Signup}
              component={RegisterStep1}
            />
            <Route exact path={RouteKeys.SignupOtp} component={RegisterStep2} />
            <AuthenticateRoute
              exact
              path={RouteKeys.SignupOnboard}
              component={RegisterStep3}
            />
            <AuthenticateRoute
              exact
              path={RouteKeys.SignupThankYou}
              component={RegisterThankYou}
            />
            <AuthenticateRoute
              // exact
              path={RouteKeys.Dashboard}
              component={DashboardPage}
            />
            <AuthenticateRoute
              path={RouteKeys.LectureVideo}
              component={LectureVideo}
            />
            <AuthenticateRoute
              path={RouteKeys.ScheduleDetails}
              component={ScheduleDetailsPage}
            />
            <AuthenticateRoute
              path={RouteKeys.QuestionAnswer}
              component={QuestionAnswer}
            />
            <Route
              path={RouteKeys.PdfRefundDocument}
              component={PdfRefundDocument}
            />
            <Route
              path={RouteKeys.PdfRefundDocumentCorporate}
              component={PdfRefundDocumentCorporate}
            />
            <Route
              path={RouteKeys.PdfClearanceCertificate}
              component={PdfClearanceCertificate}
            />
            <Route
              exact
              path={RouteKeys.BookAppointmentThankYou}
              component={BookAppointmentThankYou}
            />
            <Route
              path={RouteKeys.BookAppointment}
              component={BookAppointment}
            />
            <Route
              path={RouteKeys.CustomerFeedback}
              component={CustomerFeedback}
            />
            <Route
              path={RouteKeys.CustomerFeedbackThankYou}
              component={CustomerFeedbackThankYou}
            />

            <Route exact path={RouteKeys.Home} component={Home} />
            <Route exact path="/verify-email/:id" component={VerifyUserEmail} />
            <Route
              path="/theorytest"
              component={() => {
                window.location.href =
                  'https://accounts.ecodrive.ae/theorytest';
                return null;
              }}
            />
            <Route path={RouteKeys.PreregistrationPdfInvoice} component={PreregistrationPdfInvoicePage} />
 



            <Route exact path={RouteKeys.NotFound} component={NotFound} />
            <Route component={NotFound} />
          </Switch>
        </Suspense>
      </ErrorBoundary>
    );
  }
}

export default Routes;
