import React, { Component } from 'react';
// import PropTypes from 'prop-types';

import './CoursePlan.scss';
import Button from '../../button';
import { formatMultiLanguageText } from '../../../utils/multi-language-utils';
import { StudentCourse } from '../../../models/user';

interface CustomProps {
  course: StudentCourse;
  onSelect: (course: any) => void;
  onChangeExpanded: () => void;
  isExpanded: boolean;
  selectedId?: string;
}

interface CustomState {
  isMore: boolean;
}

class CoursePlan extends Component<CustomProps, CustomState> {
  constructor(props: CustomProps) {
    super(props);
    this.state = {
      isMore: false,
    };
  }

  renderPlanHeaderAmount() {
    return (
      <h2 className="md:text-2xl text-lg font-semibold">
        AED{' '}
        {this.props.course.amount.totalAmount -
          this.props.course.amount.totalDiscount}
      </h2>
    );
  }

  getAmountPerClass(): number | undefined {
    if (this.props.course.courseType.isLumpSumCourse) {
      return undefined;
    }
    const stage = this.props.course.stages.find(
      (st) =>
        st.feeCodes.find((fc) =>
          this.props.course.course.amountPerClassFeeCodes.includes(fc.code)
        ) !== undefined
    );
    if (!stage) {
      return undefined;
    }

    const feeCode = stage.feeCodes.find((fc) =>
      this.props.course.course.amountPerClassFeeCodes.includes(fc.code)
    );

    if (!feeCode) {
      return undefined;
    }
    return feeCode.amountPerQuantity * (stage.noOfClassesPerHour || 1);
  }

  renderLumpSumDisclaimer() {
    return (
      <p className="text-[10px] sm:text-[13px] text-muted font-light max-w-[180px] md:max-w-[300px]">
        (License Guaranteed)<sup className="text-primary">**</sup>
      </p>
    );
  }

  // eslint-disable-next-line class-methods-use-this
  renderPlanHeaderCourseTypeName(courseType: string) {
    return <span className="Plan__type">{courseType}</span>;
  }

  // eslint-disable-next-line class-methods-use-this
  renderPlanHeaderNote() {
    return (
      <p className="text-[10px] sm:text-[13px] text-muted font-light max-w-[180px] md:max-w-[300px]">
        (5% VAT applicable on Ecodrive Fees)
      </p>
    );
  }

  renderPlanHeaderSelectButton(courseType: string) {
    return (
      <div className="Plan__btn">
        <Button
          type="button"
          size="sm"
          onClick={() => {
            this.props.onSelect(this.props.course);
          }}
        >
          Choose {courseType}
        </Button>
      </div>
    );
  }

  renderPlanHeaderOriginalCourseAmount() {
    return (
      <span className="text-muted line-through">
        AED {this.props.course.amount.totalAmount}{' '}
      </span>
    );
  }

  renderPlanHeaderDiscountInfo() {
    return (
      <span className="text-primary">
        You save AED {this.props.course.amount.totalDiscount}
      </span>
    );
  }

  renderPlanHeader() {
    let courseType;
    if (this.props.course.courseType.isLumpSumCourse) {
      courseType = this.props.course.courseType.isVipCourse
        ? 'VIP Lumpsum'
        : 'Lumpsum';
    } else {
      courseType = this.props.course.courseType.name.en;
    }
    return (
      <>
        <div className="flex justify-between">
          <div
            className="custom-radio whitespace-nowrap"
            onClick={() => {
              this.props.onSelect(this.props.course);
            }}
          >
            <input
              type="radio"
              name="plan"
              id={courseType}
              value={courseType}
              // onClick={() => setPlanselect("regular")}
              // onChange={() => this.props.onSelect(this.props.course)}
              checked={
                this.props.selectedId === this.props.course.courseTypeId
                  ? true
                  : undefined
              }
            />
            <label htmlFor={courseType} className="planlabel">
              {courseType}
            </label>
          </div>
          <div className="text-right">
            {this.renderPlanHeaderAmount()}
            <p className="text-sm">
              {this.props.course.amount.totalDiscount > 0 &&
                this.renderPlanHeaderOriginalCourseAmount()}
              {this.props.course.amount.totalDiscount > 0 &&
                this.renderPlanHeaderDiscountInfo()}
            </p>
            {this.renderPlanHeaderNote()}
            {this.props.course.courseType.isLumpSumCourse &&
              this.renderLumpSumDisclaimer()}
          </div>
        </div>
        <div className="sm:ltr:pl-[30px] sm:rtl:pr-[30px] md:-mt-3">
          {!this.props.course?.courseType?.isLumpSumCourse && (
            <p className="text-sm">AED {this.getAmountPerClass()} /per hour</p>
          )}
          <p className="text-[13px] text-muted">
            This course is suggested by your driving license experience and
            Nationality
          </p>
        </div>
      </>
    );
  }

  // eslint-disable-next-line class-methods-use-this
  renderPlanListItem(
    label: string,
    value: string | number,
    key: string | number
  ) {
    return (
      <div className="grid grid-cols-10 py-3" key={`${key}`}>
        <div className="md:col-span-3 sm:col-span-4 col-span-12">
          <div className="flex">
            <span>
              <svg width="18" height="18" viewBox="0 0 18 18" fill="none">
                <circle cx="9" cy="9" r="9" fill="#151817" />
                <path
                  d="M8.06377 11.6221C8.34148 11.8055 8.71887 11.7749 8.96336 11.5304C8.96484 11.5289 8.96636 11.5275 8.96782 11.526L13.0107 7.4831C13.2901 7.2037 13.2901 6.75069 13.0107 6.47128C12.7313 6.19186 12.2783 6.19186 11.9988 6.47128L8.45746 10.0127L6.69893 8.25412C6.41952 7.97471 5.96651 7.97471 5.6871 8.25412C5.40769 8.53352 5.40769 8.98655 5.6871 9.26595L7.9468 11.5256C7.94836 11.5272 7.94994 11.5288 7.95153 11.5304C7.98645 11.5653 8.02409 11.5959 8.06377 11.6221Z"
                  fill="white"
                />
              </svg>
            </span>
            <p className="ltr:pl-4 rtl:pr-4 text-sm font-semibold">{label}</p>
          </div>
        </div>
        <div className="md:col-span-7 sm:col-span-8 col-span-12 sm:ltr:pl-0 sm:rtl:pr-0 ltr:pl-[34px] rtl:pl-[34px]">
          <p className="text-sm font-light">{value}</p>
        </div>
      </div>
    );
  }

  renderReadMoreLessButton() {
    return (
      <span
        className="text-[13px] underline mt-4 inline-block cursor-pointer"
        // onClick={this.props.onChangeExpanded}
        onClick={() => this.setState({ isMore: !this.state.isMore })}
      >
        {/* {this.props.isExpanded ? 'View less' : 'View more'} */}
        {this.state.isMore === true ? 'View less' : 'View more'}
      </span>
    );
  }

  renderInfoList() {
    const { courseType } = this.props.course;
    const infoList = [
      {
        label: 'Week Sequence',
        value:
          courseType.weekSequences && courseType.weekSequences.length
            ? formatMultiLanguageText(courseType.weekSequences[0].name) ||
              'All days'
            : 'All days',
      },
      {
        label: 'Number of hours per week',
        value: `${courseType.noOfHoursPerWeek} hrs`,
      },
      {
        label: 'Time allowed in the class type',
        value: '8.30 AM-5.30 PM & 8.00 PM-11.00 PM',
      },
      {
        label: 'Training fees per hour',
        value: courseType.isLumpSumCourse
          ? 'N/A'
          : `AED ${this.getAmountPerClass()}`,
      },
      {
        label: 'Minimum Booking duration',
        value: `${courseType.minimumClassDuration} hr`,
      },
      {
        label: 'Maximum Booking duration',
        value: `${courseType.maximumClassDuration} hr`,
      },
    ];

    return (
      // <ul className={`Plan__list ${this.props.isExpanded ? 'Expanded' : ''}`}>
      // this.props.isExpanded && (
      this.state.isMore === true && (
        <div className="pb-3 pt-6 points">
          {infoList.map((info, index) =>
            this.renderPlanListItem(info.label, info.value, index)
          )}
        </div>
      )
    );
  }

  render() {
    return (
      <div className="border rounded-md p-4 md:p-6 mb-[15px]">
        {this.renderPlanHeader()}
        <div className="sm:ltr:pl-[30px] sm:rtl:pr-[30px] md:-mt-3">
          {this.renderInfoList()}
          {this.renderReadMoreLessButton()}
        </div>
      </div>
    );
  }
}

export default CoursePlan;
