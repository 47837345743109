import * as http from './http';
import ApiService from './service';
import {
  FindScheduleRequestModel,
  ScheduleRequestModel,
  RateScheduleRequestModel,
  CommentScheduleRequestModel,
  FindTheoryLectureScheduleRequestModel,
  TheoryLectureScheduleRequestModel,
  FindMockTestSimulatorScheduleRequestModel,
  MockTestSimulatorScheduleRequestModel,
  VipInstructorSlotRequestModel,
} from '../models/schedule';
import { ScheduleResourceEnum } from '../../models/schedule';
import { Timings } from '../../models/date';
import { stringify } from 'query-string';
const serviceEndpoint = 'schedule/v1';

export default class ScheduleService extends ApiService {
  public async getSchedules(courseId: string, year: number, month: number) {
    const url = `${this.apiDomain}/${serviceEndpoint}/schedule/list?courseId=${courseId}&year=${year}&month=${month}`;
    return http.get(url, this.token);
  }

  public async getScheduleDetails(id: string) {
    const url = `${this.apiDomain}/${serviceEndpoint}/schedule/${id}`;
    return http.get(url, this.token);
  }

  public async findSchedule(data: FindScheduleRequestModel) {
    const url = `${this.apiDomain}/${serviceEndpoint}/schedule/find`;
    return http.post(url, data, this.token);
  }
  public async schedule(data: ScheduleRequestModel) {
    const url = `${this.apiDomain}/${serviceEndpoint}/schedule/book`;
    return http.post(url, data, this.token);
  }

  public async rateSchedule(id: string, data: RateScheduleRequestModel) {
    const url = `${this.apiDomain}/${serviceEndpoint}/schedule/${id}/rating`;
    return http.post(url, data, this.token);
  }

  public async cancelSchedule(id: string) {
    const url = `${this.apiDomain}/${serviceEndpoint}/schedule/${id}`;
    return http.remove(url, this.token);
  }

  public async commentSchedule(id: string, data: CommentScheduleRequestModel) {
    const url = `${this.apiDomain}/${serviceEndpoint}/schedule/${id}/comment`;
    return http.post(url, data, this.token);
  }

  public async getSchedulesByDate(
    courseId: string,
    startDate: Date,
    endDate?: Date
  ) {
    let url = `${this.apiDomain}/${serviceEndpoint}/schedule/list?courseId=${courseId}&startDate=${startDate}`;
    if (endDate) {
      url = url + `&endDate=${endDate}`;
    }
    return http.get(url, this.token);
  }

  public async findTheoryLectureSchedule(
    data: FindTheoryLectureScheduleRequestModel
  ) {
    const url = `${this.apiDomain}/${serviceEndpoint}/lecture-hall/find-schedule`;
    return http.post(url, data, this.token);
  }

  public async scheduleTheoryLecture(data: TheoryLectureScheduleRequestModel) {
    const url = `${this.apiDomain}/${serviceEndpoint}/lecture-hall/book-schedule`;
    return http.post(url, data, this.token);
  }

  public async findMockTestSchedule(
    data: FindMockTestSimulatorScheduleRequestModel
  ) {
    const url = `${this.apiDomain}/${serviceEndpoint}/mock-test/find-schedule`;
    return http.post(url, data, this.token);
  }

  public async scheduleMockTest(data: MockTestSimulatorScheduleRequestModel) {
    const url = `${this.apiDomain}/${serviceEndpoint}/mock-test/book-schedule`;
    return http.post(url, data, this.token);
  }

  public async findSimulatorTrainingSchedule(
    data: FindMockTestSimulatorScheduleRequestModel
  ) {
    const url = `${this.apiDomain}/${serviceEndpoint}/simulator-training/find-schedule`;
    return http.post(url, data, this.token);
  }

  public async scheduleSimulatorTraining(
    data: MockTestSimulatorScheduleRequestModel
  ) {
    const url = `${this.apiDomain}/${serviceEndpoint}/simulator-training/book-schedule`;
    return http.post(url, data, this.token);
  }

  public async getInstructorSlots(data: VipInstructorSlotRequestModel) {
    const url = `${this.apiDomain}/${serviceEndpoint}/availability/instructor/find-vip-slots?studentId=${data.studentId}&instanceCode=${data.instanceCode}&instructorId=${data.instructorId}&branchId=${data.branchId}&numberOfDays=${data.numberOfDays}&startDate=${data.startDate}`;
    return http.get(url, this.token);
  }

  public async bookVipSlotSchedule(data: any, studentId: string) {
    const url = `${this.apiDomain}/${serviceEndpoint}/schedule/book-custom-schedule?studentId=${studentId}`;
    return http.post(url, data, this.token);
  }

  public async getTimeSlots(
    timings: Timings[],
    date: Date,
    resource: ScheduleResourceEnum
  ) {
    const timingsString = timings.map((t) => t.toString()).join(',');
    const url = `${
      this.apiDomain
    }/${serviceEndpoint}/work-time/time-slots?timings=${timingsString}&date=${date.toISOString()}&resource=${resource}`;
    return http.get(url, this.token);
  }

  public async getPracticalTrainingTimeSlots(data: {
    instanceCode: string;
    licenseTypeId: string;
    instructorTypeId: string;
    branchId: string;
  }) {
    const url = `${
      this.apiDomain
    }/${serviceEndpoint}/work-time/practical-training/time-slots?${stringify(
      data
    )}`;
    return http.get(url, this.token);
  }
}
