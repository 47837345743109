// core
import React, { useEffect, useState } from 'react';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import { toastr } from 'react-redux-toastr';
import { useDispatch, useSelector } from 'react-redux';
import api from '../../api';
import { actions as authUserActions } from '../../store/reducers/authUser';

// components
import {
  TextInput,
  PasswordInput,
  RadioSelectInput,
  DateInput,
  SelectPhoneInput,
} from '../../components/form-group';
import { CrudDetailsRequestModel } from '../../api/models';
// helpers
import { validateFields, serializeFields, validators } from '../helpers';
import Button from '../../components/button';
import SelectInput from '../../components/form-group/SelectInput/SelectInput';

import countryCodes from '../../models/country-dialcodes.json';
import prepareData from './prepareData';
import { Dict } from '../../models/interfaces';
import { State } from '../../store/interfaces';
import ClickLink from '../../components/click-link';
import { useGTagConversionTracker } from '../../hooks/gtag';
import { GtagSendTo } from '../../utils/gtag';
import { RouteKeys } from '../route-keys';
import loaderImg from '../../assets/images/loader-white.svg';

const UAECountryName = 'United Arab Emirates';
const noVisaCountries = [
  'Saudi Arabia',
  'Kuwait',
  'United Arab Emirates',
  'Qatar',
  'Bahrain',
  'Oman',
];

const countryCodeList: Array<{ label: string; value: string }> = [];
const countryCodesList = countryCodes as { [key: string]: string };
Object.keys(countryCodes).forEach((codeKey) =>
  countryCodeList.push({
    label: countryCodesList[codeKey],
    value: countryCodesList[codeKey],
  })
);

const StepForm: React.SFC = () => {
  const location = useLocation();
  useGTagConversionTracker({
    allow_custom_scripts: true,
    u1: location.pathname,
    send_to: GtagSendTo.Signup,
  });

  const dispatch = useDispatch();
  const onSubmit = (data: any) => dispatch(authUserActions.signup(data));

  const { isLoading } = useSelector((state: State) => state.authUser);
  const [options, setOptions] = useState([
    { label: 'Automatic', value: 'automatic' },
    { label: 'Manual', value: 'manual' },
  ]);
  const [genderOptions, setGenderOptions] = useState([
    { label: 'Male', value: 'male' },
    { label: 'Female', value: 'female' },
  ]);
  const history = useHistory();

  const [fields, setFields] = useState<Dict>({
    name: {
      value: '',
      name: 'Full Name',
      label: 'Full Name *',
      error: '',
      placeholder: 'Enter full name',
      validators: [
        validators.required,
        validators.regexPattern(/^[a-zA-Z ]+$/),
      ],
    },
    countryCode: {
      value: '971',
    },
    startingCode: {
      value: '50',
    },
    number: {
      value: '',
      name: 'Phone Number',
      label: 'Phone Number *',
      error: '',
      placeholder: 'Enter your phone number',
      validators: [validators.required],
    },
    email: {
      value: '',
      name: 'Email Address',
      label: 'Email Address *',
      error: '',
      placeholder: 'Enter your email',
      validators: [validators.required, validators.email],
    },
    password: {
      value: '',
      name: 'Password',
      label: 'Password *',
      error: '',
      placeholder: 'Your password should be 6 characters long',
      validators: [
        validators.required,
        validators.minLength(
          6,
          'Password should be at least 6 characters long'
        ),
      ],
    },
    confirmPassword: {
      value: '',
      name: 'Confirm Password',
      label: 'Confirm Password *',
      error: '',
      placeholder: 'Re-enter password to confirm',
      validators: [validators.required],
    },

    licenseTypeId: {
      value: '',
      name: 'Driving License Type',
      label: 'Driving License Type *',
      placeholder: 'Select License Type',
      error: '',
      validators: [validators.required],
    },
    licenseTypeCategory: {
      value: '',
    },
    gearType: {
      value: '',
      name: 'Gear Type',
      label: 'Gear Type *',
      error: '',
      validators: [validators.required],
    },
    gender: {
      value: 'male',
      name: 'Gender',
      label: 'Gender *',
      error: '',
      validators: [validators.required],
    },

    dob: {
      value: '',
      name: 'Date Of Birth',
      label: 'Date Of Birth *',
      error: '',
      placeholder: 'DD / MM / YYYY',
      validators: [validators.required],
    },
    language: {
      value: '',
      name: 'Language',
      label: 'Language *',
      error: '',
      placeholder: 'Select your language',
      validators: [validators.required],
    },
    hasResidencyVisa: {
      value: undefined,
      name: 'Residency Visa Status',
      label: 'Do you have a residency visa in UAE? *',
      error: '',
      validators: [
        validators.required,
        // validators.equals(
        //   true,
        //   'You need to have residency Visa in UAE to register!'
        // ),
      ],
    },
    nationality: {
      value: '',
      name: 'Nationality',
      label: 'Nationality *',
      error: '',
      placeholder: 'Select your nationality',
      validators: [validators.required],
    },
  });
  const [queryValues, setQueryValues] = useState<{
    code: string;
    startingCode: string;
    number: string;
  }>();

  const queryNumber = useLocation<any>();

  useEffect(() => {
    if (queryNumber) {
      const valAr: any = {};
      queryNumber.search
        .replace('?', '')
        .split('&')
        .map((queries) => {
          const query = queries.split('=');
          return (valAr[query[0]] = query[1]);
        });
      if (valAr) {
        setQueryValues(valAr);
      }
    }
  }, [queryNumber]);

  const getInput = (inputField: string, value: any) => {
    if (inputField === 'nationality' && value === UAECountryName) {
      const fieldName = 'hasResidencyVisa';
      setFields({
        ...fields,
        [fieldName]: {
          ...fields.hasResidencyVisa,
          value: false,
        },
        [inputField]: {
          ...fields[inputField],
          value,
        },
      });
    } else {
      setFields({
        ...fields,
        [inputField]: {
          ...fields[inputField],
          value,
        },
      });
    }
  };

  const loadGearType = async (licenseTypeId: any) => {
    const request: CrudDetailsRequestModel = {
      resource: 'entity/v1/license-type',
      resourceId: licenseTypeId,
    };
    const gearTypeoptionsOptions: any = [];
    const { data } = await api.crud.get(request);
    if (data && data.gearTypes) {
      data.gearTypes.forEach((item: string) => {
        gearTypeoptionsOptions.push({
          label: item.charAt(0).toUpperCase() + item.substr(1),
          value: item.toLowerCase(),
        });
      });
      setOptions(gearTypeoptionsOptions);
    }
    const genderTypeOptions: any = [];
    if (data && data.genders) {
      data.genders.forEach((item: string) => {
        genderTypeOptions.push({
          label: item.charAt(0).toUpperCase() + item.substr(1),
          value: item.toLowerCase(),
        });
      });
      setGenderOptions(genderTypeOptions);
    }
  };

  const validateCustomFields = (customeFields: Dict) => {
    let areCustomFieldsValid = true;
    if (
      !customeFields.number.value ||
      !customeFields.countryCode.value ||
      !customeFields.startingCode.value
    ) {
      return { areCustomFieldsValid: false, updatedCustomFields: {} };
    }

    const validatorMessage = validators.phone(
      'number',
      customeFields.countryCode.value +
        customeFields.startingCode.value +
        customeFields.number.value,
      customeFields.number.name || customeFields.number.label
    );
    customeFields.number.error = validatorMessage;

    if (validatorMessage.length) {
      areCustomFieldsValid = false;
    }

    return { areCustomFieldsValid, updatedCustomFields: customeFields };
  };

  const validatePasswordFields = (customeFields: Dict) => {
    const { password, confirmPassword } = customeFields;
    if (password.value === confirmPassword.value) {
      return undefined;
    }
    return 'Password Mismatch! Both passwords must be same!';
  };

  const validateHasResidencyVisaField = (customeFields: Dict) => {
    const { hasResidencyVisa, nationality } = customeFields;
    if (
      hasResidencyVisa.value === false &&
      !noVisaCountries.includes(nationality.value)
    ) {
      return 'You need to have residency Visa in UAE to register!';
    }
    return undefined;
  };

  const submitForm = async (readyFields: Dict) => {
    const data = prepareData(readyFields);
    try {
      await onSubmit(data);
      history.replace('/signup/otp');
    } catch (error) {
      if (error && error.response && error.response.data) {
        formatErrors(error.response.data.errors);
      }
      // console.log('Login error:', error);
    }
  };

  const formatErrors = (errors: any) => {
    const errorFields: Dict = {};
    if (Array.isArray(errors)) {
      for (const error of errors) {
        const field = error.context.key;
        const formField = field === 'phone' ? 'number' : field;
        const message = `Invalid ${fields[formField].name ||
          fields[formField].label}`;
        if (fields[formField]) {
          errorFields[formField] = {
            ...fields[formField],
            error: message,
          };
        } else {
          toastr.error(message, '');
        }
      }
    } else {
      const errorkeys = Object.keys(errors);
      for (const field of errorkeys) {
        const formField = field === 'phone' ? 'number' : field;

        const message =
          errors[field].message ||
          errors[field].errorMessage ||
          `Invalid ${fields[formField].name || fields[formField].label}`;
        if (fields[formField]) {
          errorFields[formField] = {
            ...fields[formField],
            error: message,
          };
        } else {
          toastr.error(message, '');
        }
      }
    }

    setFields({
      ...fields,
      ...errorFields,
    });
  };

  const submitHandler = (e: any) => {
    e.preventDefault();
    const validateOptions = {
      fieldsToValidate: fields,
    };
    const { areFieldsValid, updatedFields } = validateFields(validateOptions);
    const validatedFields = { ...fields, ...updatedFields };
    if (!areFieldsValid) {
      setFields(validatedFields);
      return;
    }
    const { areCustomFieldsValid, updatedCustomFields } = validateCustomFields(
      validatedFields
    );
    // show error messages (if any)
    const updatedValidatedFields = {
      ...validatedFields,
      ...updatedCustomFields,
    };
    if (!areCustomFieldsValid) {
      setFields(updatedValidatedFields);
      return;
    }
    const passwordMatchErrorMessage = validatePasswordFields(
      updatedValidatedFields
    );
    if (passwordMatchErrorMessage) {
      setFields({
        ...updatedValidatedFields,
        confirmPassword: {
          ...updatedValidatedFields.confirmPassword,
          error: passwordMatchErrorMessage,
        },
      });
      return;
    }
    const residencyVisaErrorMessage = validateHasResidencyVisaField(
      updatedValidatedFields
    );
    if (residencyVisaErrorMessage) {
      setFields({
        ...updatedValidatedFields,
        hasResidencyVisa: {
          ...updatedValidatedFields.hasResidencyVisa,
          error: residencyVisaErrorMessage,
        },
      });
      return;
    }
    const readyFields = serializeFields(fields);
    submitForm(readyFields);
  };

  const renderFieldGroupOne = () => {
    return (
      <>
        <div className="col-span-2 md:col-span-1">
          <SelectInput
            api="entity/v1/license-type"
            label={fields.licenseTypeId.label}
            placeholder={fields.licenseTypeId.placeholder}
            optionLabel="name"
            optionValue="_id"
            onChange={(value, selectedOption) => {
              setFields({
                ...fields,
                licenseTypeId: {
                  ...fields.licenseTypeId,
                  value,
                },
                licenseTypeCategory: {
                  ...fields.licenseTypeCategory,
                  value: selectedOption.name,
                },
                gearType: {
                  ...fields.gearType,
                  value: '',
                },
              });
              loadGearType(value);
            }}
            error={fields.licenseTypeId.error}
            isSearchable={false}
            default={fields.licenseTypeId.value}
            className="form-control-select"
          />
        </div>
        {/* {fields.licenseTypeId.value && ( */}
        <div className="col-span-2 md:col-span-1">
          <RadioSelectInput
            name="gearType"
            key="gearType"
            label={fields.gearType.label}
            options={options}
            onChange={(value) => getInput('gearType', value)}
            error={fields.gearType.error}
            default={fields.gearType.value}
          />
        </div>
        {/* )} */}
      </>
    );
  };

  const renderFieldGroupTwo = () => {
    return (
      <>
        <div className="col-span-2 md:col-span-1">
          <TextInput
            label={fields.name.label}
            default={fields.name.value}
            onChange={(value) => {
              getInput('name', value);
            }}
            placeholder={fields.name.placeholder}
            error={fields.name.error}
            // width={{ select: 20, text: 80 }}
            className="form-control"
          />
        </div>
        <div className="col-span-2 md:col-span-1">
          <TextInput
            label={fields.email.label}
            onChange={(value) => getInput('email', value)}
            placeholder={fields.email.placeholder}
            error={fields.email.error}
            default={fields.email.value}
            className="form-control"
          />
        </div>
      </>
    );
  };

  const renderFieldGroupThree = () => {
    return (
      <>
        <div className="col-span-2 md:col-span-1">
          <RadioSelectInput
            name="gender"
            key="gender"
            label={fields.gender.label}
            options={genderOptions}
            onChange={(value) => getInput('gender', value)}
            error={fields.gender.error}
            default={fields.gender.value}
          />
        </div>
        <div className="col-span-2 md:col-span-1">
          <SelectPhoneInput
            type="number"
            label={fields.number.label}
            data={countryCodeList}
            defaultCode={fields.countryCode.value}
            defaultSelected={
              queryValues?.startingCode || fields.startingCode.value
            }
            defaultEntered={queryValues?.number || fields.number.value}
            onChange={(value) => {
              setFields({
                ...fields,
                countryCode: {
                  ...fields.countryCode,
                  value: value.code,
                },
                startingCode: {
                  ...fields.startingCode,
                  value: value.selected,
                },
                number: {
                  ...fields.number,
                  value: value.entered,
                },
              });
            }}
            placeholder={fields.number.placeholder}
            error={fields.number.error}
            enableSelectSearch
            disableNumberScrolls
            outerClass="form-control items-center relative ltr:pl-[60px] rtl:pr-[60px]"
            codeInputClass="absolute leading-4 top-0 bottom-0 ltr:left-0 rtl:right-0 flex items-center ltr:pl-5 rtl:pr-5 bg-[#F8F8F8] w-[60px] ltr:border-r rtl:border-l ltr:rounded-tl-md ltr:rounded-bl-md rtl:rounded-tr-md rtl:rounded-br-md pt-1 h-full"
            innerClass="flex items-center w-full relative top-[-1px] w-full h-[23px]"
          />
        </div>
      </>
    );
  };

  const renderFieldGroupFour = () => {
    return (
      <>
        <div className="col-span-2 md:col-span-1">
          <DateInput
            label={fields.dob.label}
            onChange={(value) => getInput('dob', value)}
            placeholder={fields.dob.placeholder}
            error={fields.dob.error}
            default={fields.dob.value}
            maxDate={new Date()}
            className="form-control"
          />
        </div>

        <div className="col-span-2 md:col-span-1">
          <SelectInput
            api="entity/v1/languages"
            label={fields.language.label}
            placeholder={fields.language.placeholder}
            optionLabel="name"
            optionValue="name"
            onChange={(value) => getInput('language', value)}
            error={fields.language.error}
            className="form-control-select"
          />
        </div>
      </>
    );
  };

  const renderFieldGroupFive = () => {
    return (
      <>
        <div className="col-span-2 md:col-span-1">
          <SelectInput
            api="entity/v1/countries"
            label={fields.nationality.label}
            placeholder={fields.nationality.placeholder}
            optionLabel="name"
            optionValue="name"
            onChange={(value) => getInput('nationality', value)}
            error={fields.nationality.error}
            className="form-control-select"
          />
        </div>
        <div className="col-span-2 md:col-span-1">
          <RadioSelectInput
            type="boolean"
            name="hasResidencyVisa"
            key="hasResidencyVisa"
            label={fields.hasResidencyVisa.label}
            options={[
              { label: 'Yes', value: true },
              { label: 'No', value: false },
            ]}
            onChange={(value) => getInput('hasResidencyVisa', value)}
            error={fields.hasResidencyVisa.error}
            default={fields.hasResidencyVisa.value}
          />
        </div>
      </>
    );
  };
  const renderFieldGroupSix = () => {
    return (
      <>
        <div className="col-span-2 md:col-span-1">
          <PasswordInput
            label={fields.password.label}
            onChange={(value) => getInput('password', value)}
            placeholder={fields.password.placeholder}
            error={fields.password.error}
            className="form-control"
          />
        </div>
        <div className="col-span-2 md:col-span-1">
          <PasswordInput
            label={fields.confirmPassword.label}
            onChange={(value) => getInput('confirmPassword', value)}
            placeholder={fields.confirmPassword.placeholder}
            error={fields.confirmPassword.error}
            className="form-control"
          />
        </div>
      </>
    );
  };

  const renderSubmitButton = () => {
    return (
      <div className="col-span-2 md:col-span-1 py-2 pb-3">
        <button
          type="submit"
          disabled={isLoading}
          className="btn btn-primary w-full md:max-w-[320px] inline-flex justify-center"
        >
          {isLoading && <img src={loaderImg} width={20} className="mr-2" />}
          Next
        </button>
      </div>
    );
  };

  return (
    <div className="bg-muted/5">
      <div className="pt-[110px] md:pt-[200px] pb-[85px]">
        <div className="container">
          <div className="text-center">
            <h1 className="text-2xl md:text-[32px] font-bold">Register</h1>
            <p className="text-muted text-sm md:text-base pt-2">
              Already have an account?
              <Link to={RouteKeys.Login}>
                <a className="underline text-primary font-thin ml-1">Login</a>
              </Link>
            </p>
          </div>
          <form onSubmit={submitHandler}>
            <div
              className="bg-white shadow-[0px_3px_5px_rgba(0,0,0,0.1)] rounded-md p-4 md:px-14 md:py-12 mt-4 md:mt-9"
              style={{ boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.1)' }}
            >
              <div className="grid grid-cols-2 gap-3 md:gap-10 pb-8">
                {/* <div className="col-span-2 md:col-span-1">
                  <div className="upload relative bg-primary rounded-md px-6 py-4 text-white">
                    <h4 className="text-lg font-bold">Read Frontside EID</h4>
                    <p className="text-sm font-thin">
                      Upload from your Computer
                    </p>
                    <div className="absolute ltr:right-6 rtl:left-6 top-[50%] translate-y-[-50%]">
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <g clipPath="url(#clip0_2810_31838)">
                          <path
                            d="M16 16L12 12L8 16"
                            stroke="white"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M12 12V21"
                            stroke="white"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M20.39 18.3905C21.3653 17.8587 22.1358 17.0174 22.5798 15.9991C23.0239 14.9808 23.1162 13.8437 22.8422 12.7672C22.5682 11.6906 21.9434 10.736 21.0666 10.0539C20.1898 9.37185 19.1108 9.00121 18 9.00047H16.74C16.4373 7.82971 15.8731 6.74281 15.0899 5.82147C14.3067 4.90012 13.3248 4.16832 12.2181 3.68108C11.1113 3.19384 9.90851 2.96383 8.70008 3.00835C7.49164 3.05288 6.30903 3.37077 5.24114 3.93814C4.17325 4.5055 3.24787 5.30758 2.53458 6.28405C1.82129 7.26053 1.33865 8.38601 1.12294 9.57587C0.90723 10.7657 0.964065 11.989 1.28917 13.1537C1.61428 14.3185 2.1992 15.3943 2.99996 16.3005"
                            stroke="white"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M16 16L12 12L8 16"
                            stroke="white"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_2810_31838">
                            <rect width="24" height="24" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                    <input
                      type={'file'}
                      className="absolute bottom-0 left-0 top-0 w-full z-40 opacity-0 cursor-pointer"
                    />
                  </div>
                </div>
                <div className="col-span-2 md:col-span-1">
                  <div className="upload relative bg-primary rounded-md px-6 py-4 text-white">
                    <h4 className="text-lg font-bold">Read Backside EID</h4>
                    <p className="text-sm font-thin">
                      Upload from your Computer
                    </p>
                    <div className="absolute ltr:right-6 rtl:left-6 top-[50%] translate-y-[-50%]">
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <g clipPath="url(#clip0_2810_31838)">
                          <path
                            d="M16 16L12 12L8 16"
                            stroke="white"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M12 12V21"
                            stroke="white"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M20.39 18.3905C21.3653 17.8587 22.1358 17.0174 22.5798 15.9991C23.0239 14.9808 23.1162 13.8437 22.8422 12.7672C22.5682 11.6906 21.9434 10.736 21.0666 10.0539C20.1898 9.37185 19.1108 9.00121 18 9.00047H16.74C16.4373 7.82971 15.8731 6.74281 15.0899 5.82147C14.3067 4.90012 13.3248 4.16832 12.2181 3.68108C11.1113 3.19384 9.90851 2.96383 8.70008 3.00835C7.49164 3.05288 6.30903 3.37077 5.24114 3.93814C4.17325 4.5055 3.24787 5.30758 2.53458 6.28405C1.82129 7.26053 1.33865 8.38601 1.12294 9.57587C0.90723 10.7657 0.964065 11.989 1.28917 13.1537C1.61428 14.3185 2.1992 15.3943 2.99996 16.3005"
                            stroke="white"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M16 16L12 12L8 16"
                            stroke="white"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_2810_31838">
                            <rect width="24" height="24" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                    <input
                      type={'file'}
                      className="absolute bottom-0 left-0 top-0 w-full z-40 opacity-0 cursor-pointer"
                    />
                  </div>
                </div> */}
              </div>
              <div className="grid grid-cols-2 gap-x-10 md:gap-y-7 gap-5">
                {renderFieldGroupOne()}
                {renderFieldGroupTwo()}
                {renderFieldGroupThree()}
                {renderFieldGroupFour()}
                {renderFieldGroupFive()}
                {renderFieldGroupSix()}
                {renderSubmitButton()}
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default StepForm;
